// App.js
import React, { useState, useEffect } from 'react';
import { ChakraProvider, CSSReset, Flex, Box, Heading, Image, Text, Link, HStack, Spinner, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import vk_music from './platforms/vk_music.svg';
import apple from './platforms/apple.svg';
import spotify from './platforms/spotify.svg';
import yandex from './platforms/yandex.svg';
import yt_music from './platforms/yt_music.svg';
import mts from './platforms/mts.svg';
import deezer from './platforms/deezer.svg'
import logo from './logo.png';
import vk_s from './socials/VK.svg';
import yt_s from './socials/YouTube.svg';
import tk_s from './socials/TikTok.svg';

import axios from 'axios'; // Убедитесь, что вы установили библиотеку Axios: npm install axios

// Получение значения из адресной строки (например, после / в ссылке)

// Объявление переменных на уровне файла


function App() {
  const [formData, setFormData] = useState({
    a_link_e: '',
    a_cover: '',
    a_title: '',
    a_artist: '',
    a_vk_link: '',
    a_ya_link: '',
    a_sp_link: '',
    a_yt_link: '',
    a_ap_link: '',
    a_password: ''
  });

  const [data, setData] = useState({
    cover: '',
    title: 'N/A',
    artist: 'N/A',
    vk_link: '',
    ya_link: '',
    sp_link: '',
    yt_link: '',
    ap_link: '',
    dr_link: ''
  });

  const [admin, setAdmin] = useState(null);

  // Состояние для отслеживания статуса загрузки
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Получение значения из адресной строки (например, после / в ссылке)
    const pathParam = window.location.pathname.substring(1);

    // Формирование URL для запроса к API
    const apiUrl = `https://api.needlmusic.ru/get_link?id=${pathParam}`;
    setAdmin(pathParam);

    axios.get(apiUrl)
      .then(response => {
        // Обработка ответа от сервера
        const responseData = response.data;
        setData({
          cover: 'https://api.needlmusic.ru' + responseData.link.release.cover,
          title: responseData.link.release.title,
          artist: responseData.link.release.artists,
          vk_link: responseData.link.links.vk,
          ya_link: responseData.link.links.yandex,
          sp_link: responseData.link.links.spotify,
          yt_link: responseData.link.links.youtube,
          ap_link: responseData.link.links.apple,
          dr_link: responseData.link.links.deezer
        });

        document.title = responseData.link.release.artists + ' - ' + responseData.link.release.title + ' | NeedlMusic';
        const metaDescriptionTag = document.querySelector('meta[name="description"]');
        if (metaDescriptionTag) {
          metaDescriptionTag.setAttribute('content', `Слушайте ${responseData.link.release.artists} - ${responseData.link.release.title} на всех площадках!`);
        }

        // Установка статуса загрузки в false после получения данных
        setLoading(false);
      })
      .catch(error => {
        // Обработка ошибок при запросе
        console.error('Error fetching data:', error);
      });


  }, []);


  if (data.cover && admin != 'admin-panel') {
    return (

      <ChakraProvider>
        <CSSReset />
        <Flex
          direction="column"
          align="center"
          justify="center"
          minH="100vh"
          position="relative" // Make the position relative for the absolute positioning of the background
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundImage={`url(https://sun9-76.userapi.com/impg/ix5jLc6libOOUBq2LUQo_MFLdCgRohCov7uclw/ZPeTbnvfbJM.jpg?size=1280x1280&quality=95&sign=7c0b25f44a1b298b4d532e318b7ff369&type=album)`}
            backgroundPosition="50% 50%"
            backgroundSize="cover"
            filter="blur(40px)" // Set the blur filter to blur the background
            zIndex="-1" // Send the background to the back
          />

          <Box
            maxW="80%"
            p="0"
            mt="18px" // Add margin-top
            ml="10%" // Add margin-left
            mr="10%" // Add margin-right
            borderRadius="35px"
            background="var(--BG-LOGIN-GRADIENT, linear-gradient(161deg, #40B0BF -48.14%, #FFFEFF 52.43%, #FFE1F3 141.09%))"
            boxShadow="0px 12px 63px -1px rgba(64, 176, 191, 1)"
            color="white"
          >

            {/* Cover Image */}
            <Image
              src={loading ? <Spinner /> : `https://image.needlmusic.ru/resize?image=${data.cover}&w=500&h=500`}
              alt="Cover Image"
              boxSize="300px" // Set the width and height to 486px
              width="300px" // Set the width
              height="300px" // Set the height
              objectFit="cover"
              borderRadius="35px"
              background={loading ? <Spinner /> : data.cover}
              flexShrink={0} // Set flex-shrink to 0
            />




            {/* Title and Artist */}
            <Box textAlign="center" mt="4" color="black"> {/* Set text color to black */}
              <Heading as="h2" size="lg">
                {loading ? <Spinner /> : (
                  <>
                    {data.title.length > 15 ?
                      data.title.split('').map((char, index) => (
                        <React.Fragment key={index}>
                          {index > 0 && index % 15 === 0 && <br />}
                          {char}
                        </React.Fragment>
                      )) :
                      data.title
                    }
                  </>
                )}
              </Heading>
              <Text fontSize="md" color="gray.400">
                {loading ? <Spinner /> : data.artist}
              </Text>
            </Box>


            {/* Music Platforms */}
            {data.vk_link && (
              <Box
                mt="3" // Adjust the margin-top value to control the spacing
                ml="1"
                mr="1"
                borderWidth="1px"
                borderRadius="15px"
                overflow="hidden"
                display="flex"
                width="290px"
                flexDirection="column"
                alignItems="flex-start"
                gap="15px" // Reduced the gap
              >
                <HStack
                  spacing="4"
                  alignItems="center"
                  justifyContent="space-between" // Добавлено выравнивание по горизонтали
                >
                  <Box /*ml="1%"*/ display="flex" alignItems="center">
                    <Image
                      src={vk_music}
                      ml={1}
                      mt={1}
                      mb={1}
                      alt="Platform Logo"
                      boxSize="25%"
                      height="40px"
                      width="40px"
                      objectFit="cover"
                      /*borderRadius="full"*/
                      flexShrink={0}
                    />
                    <Text fontSize="md" fontWeight="bold" color="black" textAlign="center" ml="4" mr={0}>
                      VK Музыка
                    </Text>
                  </Box>

                  <Link
                    href={data.vk_link}
                    isExternal
                    color="white"
                    _hover={{ color: 'white' }}
                    display="inline-block"
                    px="3"
                    py="1"
                    borderRadius="10px"
                    bg="var(--MAIN, #54c2d1)"
                    textDecoration="none"
                    fontWeight="semibold"
                    ml={7}

                  >
                    Слушать
                  </Link>
                </HStack>
              </Box>)}
            {data.ya_link && (
              <Box
                mt="3" // Adjust the margin-top value to control the spacing
                ml="1"
                mr="1"
                borderWidth="1px"
                borderRadius="15px"
                overflow="hidden"
                display="flex"
                width="290px"
                flexDirection="column"
                alignItems="flex-start"
                gap="15px" // Reduced the gap
              >
                <HStack
                  spacing="4"
                  alignItems="center"
                  justifyContent="space-between" // Добавлено выравнивание по горизонтали
                >
                  <Box /*ml="1%"*/ display="flex" alignItems="center">
                    <Image
                      src={yandex}
                      ml={1}
                      mt={1}
                      mb={1}
                      alt="Platform Logo"
                      boxSize="25%"
                      height="40px"
                      width="40px"
                      objectFit="cover"
                      /*borderRadius="full"*/
                      flexShrink={0}
                    />
                    <Text fontSize="md" fontWeight="bold" color="black" textAlign="center" ml="4" mr={0}>
                      Yandex Music
                    </Text>
                  </Box>

                  <Link
                    href={data.ya_link}
                    isExternal
                    color="white"
                    _hover={{ color: 'white' }}
                    display="inline-block"
                    px="3"
                    py="1"
                    borderRadius="10px"
                    bg="var(--MAIN, #54c2d1)"
                    textDecoration="none"
                    fontWeight="semibold"
                    ml={3}

                  >
                    Слушать
                  </Link>
                </HStack>
              </Box>)}
            {data.sp_link && (
              <Box
                mt="3" // Adjust the margin-top value to control the spacing
                ml="1"
                mr="1"
                borderWidth="1px"
                borderRadius="15px"
                overflow="hidden"
                display="flex"
                width="290px"
                flexDirection="column"
                alignItems="flex-start"
                gap="15px" // Reduced the gap
              >
                <HStack
                  spacing="4"
                  alignItems="center"
                  justifyContent="space-between" // Добавлено выравнивание по горизонтали
                >
                  <Box /*ml="1%"*/ display="flex" alignItems="center">
                    <Image
                      src={spotify}
                      ml={1}
                      mt={1}
                      mb={1}
                      alt="Platform Logo"
                      boxSize="25%"
                      height="40px"
                      width="40px"
                      objectFit="cover"
                      /*borderRadius="full"*/
                      flexShrink={0}
                    />
                    <Text fontSize="md" fontWeight="bold" color="black" textAlign="center" ml="4" mr={5}>
                      Spotify
                    </Text>
                  </Box>

                  <Link
                    href={data.sp_link}
                    isExternal
                    color="white"
                    _hover={{ color: 'white' }}
                    display="inline-block"
                    px="3"
                    py="1"
                    borderRadius="10px"
                    bg="var(--MAIN, #54c2d1)"
                    textDecoration="none"
                    fontWeight="semibold"
                    ml={10}

                  >
                    Слушать
                  </Link>
                </HStack>
              </Box>)}
            {data.ap_link && (
              <Box
                mt="3" // Adjust the margin-top value to control the spacing
                ml="1"
                mr="1"
                borderWidth="1px"
                borderRadius="15px"
                overflow="hidden"
                display="flex"
                width="290px"
                flexDirection="column"
                alignItems="flex-start"
                gap="15px" // Reduced the gap
              >
                <HStack
                  spacing="4"
                  alignItems="center"
                  justifyContent="space-between" // Добавлено выравнивание по горизонтали
                >
                  <Box /*ml="1%"*/ display="flex" alignItems="center">
                    <Image
                      src={apple}
                      ml={1}
                      mt={1}
                      mb={1}
                      alt="Platform Logo"
                      boxSize="25%"
                      height="40px"
                      width="40px"
                      objectFit="cover"
                      /*borderRadius="full"*/
                      flexShrink={0}
                    />
                    <Text fontSize="md" fontWeight="bold" color="black" textAlign="center" ml="4" mr={0}>
                      Apple Music
                    </Text>
                  </Box>

                  <Link
                    href={data.ap_link}
                    isExternal
                    color="white"
                    _hover={{ color: 'white' }}
                    display="inline-block"
                    px="3"
                    py="1"
                    borderRadius="10px"
                    bg="var(--MAIN, #54c2d1)"
                    textDecoration="none"
                    fontWeight="semibold"
                    ml="22px"

                  >
                    Слушать
                  </Link>
                </HStack>
              </Box>)}
            {data.yt_link && (
              <Box
                mt="3" // Adjust the margin-top value to control the spacing
                ml="1"
                mr="1"
                borderWidth="1px"
                borderRadius="15px"
                overflow="hidden"
                display="flex"
                width="290px"
                flexDirection="column"
                alignItems="flex-start"
                gap="15px" // Reduced the gap
              >
                <HStack
                  spacing="4"
                  alignItems="center"
                  justifyContent="space-between" // Добавлено выравнивание по горизонтали
                >
                  <Box /*ml="1%"*/ display="flex" alignItems="center">
                    <Image
                      src={yt_music}
                      ml={1}
                      mt={1}
                      mb={1}
                      alt="Platform Logo"
                      boxSize="25%"
                      height="40px"
                      width="40px"
                      objectFit="cover"
                      /*borderRadius="full"*/
                      flexShrink={0}
                    />
                    <Text fontSize="md" fontWeight="bold" color="black" textAlign="center" ml="4" mr={0}>
                      YouTube Music
                    </Text>
                  </Box>

                  <Link
                    href={data.yt_link}
                    isExternal
                    color="white"
                    _hover={{ color: 'white' }}
                    display="inline-block"
                    px="3"
                    py="1"
                    borderRadius="10px"
                    bg="var(--MAIN, #54c2d1)"
                    textDecoration="none"
                    fontWeight="semibold"
                    ml="2px"

                  >
                    Слушать
                  </Link>
                </HStack>
              </Box>)}
            {data.dr_link && (
              <Box
                mt="3" // Adjust the margin-top value to control the spacing
                ml="1"
                mr="1"
                borderWidth="1px"
                borderRadius="15px"
                overflow="hidden"
                display="flex"
                width="290px"
                flexDirection="column"
                alignItems="flex-start"
                gap="15px" // Reduced the gap
              >
                <HStack
                  spacing="4"
                  alignItems="center"
                  justifyContent="space-between" // Добавлено выравнивание по горизонтали
                >
                  <Box /*ml="1%"*/ display="flex" alignItems="center">
                    <Image
                      src={deezer}
                      ml={1}
                      mt={1}
                      mb={1}
                      alt="Platform Logo"
                      boxSize="25%"
                      height="40px"
                      width="40px"
                      objectFit="cover"
                      /*borderRadius="full"*/
                      flexShrink={0}
                    />
                    <Text fontSize="md" fontWeight="bold" color="black" textAlign="center" ml="4" mr={0}>
                      Deezer
                    </Text>
                  </Box>

                  <Link
                    href={data.dr_link}
                    isExternal
                    color="white"
                    _hover={{ color: 'white' }}
                    display="inline-block"
                    px="3"
                    py="1"
                    borderRadius="10px"
                    bg="var(--MAIN, #54c2d1)"
                    textDecoration="none"
                    fontWeight="semibold"
                    ml="63px"

                  >
                    Слушать
                  </Link>
                </HStack>
              </Box>)}

            <br />


          </Box>


          {/* Footer */}
          <Box mt="8" textAlign="center" color="gray.500">
            <Image src={logo} width="250px" />
            <Box mt="2" display="flex" justifyContent="center">
              {/* Замените ссылки в href на ваши реальные ссылки */}
              <Link href="https://vk.com/needlmusic" isExternal mx="2" aria-label="VK">
                <Image src={vk_s} boxSize="7" />
              </Link>
              <Link href="https://music.yandex.ru/label/4928721" isExternal mx="2" aria-label="Yandex">
                <Image src={yandex} boxSize="7" />
              </Link>
              {/*<Link href="#" isExternal mx="2" aria-label="Instagram">
                <Image src={tk_s} boxSize="7" />
              </Link>
              <Link href="#" isExternal mx="2" aria-label="Instagram">
                <Image src={yt_s} boxSize="7" />
    </Link>*/}
              {/* Добавьте дополнительные кнопки для других соцсетей по необходимости */}
            </Box><br />
            <Text>&copy; 2024 NeedlMusic</Text>
          </Box>
        </Flex>
      </ChakraProvider>
    );
  }
  else if (admin == 'admin-panel') {
    // Обработчик изменения значений полей формы
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value
      });
    };

    // Обработчик отправки формы
    const handleSubmit = async (e) => {
      e.preventDefault();

      try {
        // Отправка данных на сервер с использованием axios
        const response = await axios.post('https://server.nlmlink.ru/api/admin-panel', formData);

        // Обработка успешного ответа от сервера


        // Очистка формы или другие действия при успешной отправке
        setFormData({
          a_link_e: '',
          a_cover: '',
          a_title: '',
          a_artist: '',
          a_vk_link: '',
          a_ya_link: '',
          a_sp_link: '',
          a_yt_link: '',
          a_ap_link: '',
          a_password: ''
        });
      } catch (error) {
        // Обработка ошибки при отправке данных
        console.error('Ошибка отправки данных на сервер:', error);
      }
    };

    return (
      <ChakraProvider>
        <Box p={8} borderWidth="1px" borderRadius="lg">
          <form onSubmit={handleSubmit}>
            <FormControl mb={4}>
              <FormLabel>Ссылка</FormLabel>
              <Input type="text" name="a_link_e" onChange={handleChange} value={formData.a_link_e} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Обложка</FormLabel>
              <Input type="text" name="a_cover" onChange={handleChange} value={formData.a_cover} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Название</FormLabel>
              <Input type="text" name="a_title" onChange={handleChange} value={formData.a_title} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Артист</FormLabel>
              <Input type="text" name="a_artist" onChange={handleChange} value={formData.a_artist} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>ВКонтакте</FormLabel>
              <Input type="text" name="a_vk_link" onChange={handleChange} value={formData.a_vk_link} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Яндекс</FormLabel>
              <Input type="text" name="a_ya_link" onChange={handleChange} value={formData.a_ya_link} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Спотифай</FormLabel>
              <Input type="text" name="a_sp_link" onChange={handleChange} value={formData.a_sp_link} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Ютуб</FormLabel>
              <Input type="text" name="a_yt_link" onChange={handleChange} value={formData.a_yt_link} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Эпл</FormLabel>
              <Input type="text" name="a_ap_link" onChange={handleChange} value={formData.a_ap_link} />
            </FormControl>

            <FormControl mb={4}>
              <FormLabel>Пароль</FormLabel>
              <Input type="password" name="a_password" onChange={handleChange} value={formData.a_password} />
            </FormControl>

            <Button type="submit" colorScheme="teal" mt={4}>
              Отправить
            </Button>
          </form>
        </Box>
      </ChakraProvider>
    )
  }
  else if (admin != 'admin-panel') {
    return (
      <ChakraProvider>
        <CSSReset />
        <Flex
          direction="column"
          align="center"
          justify="center"
          minH="100vh"
          position="relative" // Make the position relative for the absolute positioning of the background
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            backgroundImage={`url(https://sun9-76.userapi.com/impg/ix5jLc6libOOUBq2LUQo_MFLdCgRohCov7uclw/ZPeTbnvfbJM.jpg?size=1280x1280&quality=95&sign=7c0b25f44a1b298b4d532e318b7ff369&type=album)`}
            backgroundPosition="50% 50%"
            backgroundSize="cover"
            filter="blur(40px)" // Set the blur filter to blur the background
            zIndex="-1" // Send the background to the back
          />




          {/* Footer */}
          <Box mt="8" textAlign="center" color="gray.500">
            <Image src={logo} width="250px" />
            <Box mt="2" display="flex" justifyContent="center">
              {/* Замените ссылки в href на ваши реальные ссылки */}
              <Link href="#" isExternal mx="2" aria-label="VK">
                <Image src={vk_s} boxSize="7" />
              </Link>
              <Link href="#" isExternal mx="2" aria-label="Yandex">
                <Image src={yandex} boxSize="7" />
              </Link>
              {/*<Link href="#" isExternal mx="2" aria-label="Instagram">
                <Image src={tk_s} boxSize="7" />
              </Link>
              <Link href="#" isExternal mx="2" aria-label="Instagram">
                <Image src={yt_s} boxSize="7" />
    </Link>*/}
              {/* Добавьте дополнительные кнопки для других соцсетей по необходимости */}
            </Box><br />
            <Text>&copy; 2024 NeedlMusic</Text>
          </Box>
        </Flex>
      </ChakraProvider>
    )
  }
}

export default App;
